<template>
  <div class="checkout-step-page">
    <Breadcrumbs />
    <section class="checkout-detail-page">
      <b-container>
        <div class="row -mx-10">
          <b-col lg="4" class="px-10">
            <CheckoutAddress ref="address" />
          </b-col>
          <b-col lg="4" class="px-10">
            <CheckoutPayment />
          </b-col>
          <b-col lg="4" class="px-10">
            <CheckoutOverview />
          </b-col>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import CheckoutAddress from "@/base/core/components/checkout/CheckoutAddress.vue";
import CheckoutPayment from "@/base/core/components/checkout/CheckoutPayment.vue";
import CheckoutOverview from "@/base/core/components/checkout/CheckoutOverview.vue";

export default {
  name: "CheckoutPage",
  components: {
    Breadcrumbs,
    CheckoutAddress,
    CheckoutPayment,
    CheckoutOverview,
  },
  metaInfo() {
    return {
      title: "Checkout",
      // all titles will be injected into this template
    };
  },
  methods: {
    clearLocalStorage() {
      localStorage.removeItem("checkoutAddress");
      localStorage.removeItem("checkoutPayment");
      localStorage.removeItem("checkoutOverview");
    },
  },

  mounted() {
    this.$root.$on("clearLocalStorage", () => {
      this.clearLocalStorage();
    });
  },
};
</script>

<style lang="scss">
</style>
