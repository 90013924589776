<template>
  <div class="first-col">
    <div class="group">
      <span class="group-title"
        ><i class="fa fa-user"></i>{{ $t("my_information") }}</span
      >
      <div class="wrap-box">
        <b-form-group
          :label="$t('form_email') + '*'"
          label-for="email"
          description="De bevestiging wordt naar dit e-mailadres verzonden."
          class="email-with-note"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="text"
            :state="form.emailState"
            aria-describedby="email-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="email-feedback">
            {{ form.emailError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div v-if="emailExists == false" class="">
          <b-form-checkbox
            id="checkbox-3"
            name="checkbox-3"
            v-model="isCreateAccount"
            >{{ $t("Maak een account voor mij aan") }}</b-form-checkbox
          >

          <div v-if="isCreateAccount">
            <b-form-group
              id="create-acc-password-group"
              :label="$t('form_password')"
              label-for="password"
            >
              <b-form-input
                id="create-acc-password"
                key="create-acc-password"
                type="password"
                v-model="form.password"
                :state="form.passwordState"
                aria-describedby="create-acc-password-feedback"
              ></b-form-input>
              <client-only>
                <password-meter :password="form.password" />
              </client-only>
              <b-form-invalid-feedback id="create-acc-password-feedback">
                {{ form.passwordError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="create-acc-password-confirm-group"
              :label="$t('form_password_confirmation') + '*'"
              label-for="password_confirm"
            >
              <b-form-input
                id="create-acc-password-confirm"
                type="password"
                v-model="form.password_confirm"
                :state="form.password_confirmState"
                aria-describedby="create-acc-password-confirm-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                id="create-acc-password-confirm-feedback"
              >
                {{ form.password_confirmError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div v-else-if="emailExists == true" class="password-field">
          <b-form-group
            id="password-group"
            :label="$t('form_password') + '*'"
            label-for="password"
          >
            <b-form-input
              key="password"
              id="password"
              v-model="form.password"
              type="password"
              aria-describedby="password-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="password-feedback">
              {{ form.passwordError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="bottom">
            <b-link to="/forgot-password" class="forgot-link">{{
              $t("password_forgot")
            }}</b-link>
            <b-button
              type="button"
              @click="doLogin"
              class="c-btn c-btn-primary"
              >{{ $t("login") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="group">
      <span class="group-title"
        ><i class="fas fa-home"></i>{{ $t("shipping_address") }}</span
      >
      <div class="wrap-box">
        <b-form-checkbox
          id="checkbox-1"
          v-model="isCompany"
          name="checkbox-1"
          >{{ $t("form_are_you_a_company") }}</b-form-checkbox
        >
        <div v-if="isCompany" class="short-form">
          <b-form-group
            class="account-inputs"
            id="company-group"
            key="company"
            :label="$t('form_company_name') + '*'"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="form.company"
              type="text"
              v-on:blur="validateCompany"
              :state="form.companyState"
              aria-describedby="company-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="company-feedback">
              {{ form.companyError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="coc_number-group"
            key="coc_number"
            :label="$t('form_coc_number') + '*'"
            label-for="coc_number"
          >
            <b-form-input
              id="coc_number"
              v-model="form.coc_number"
              type="text"
              v-on:blur="validateCOCNumber"
              :state="form.coc_numberState"
              aria-describedby="coc_number-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="coc_number-feedback">
              {{ form.coc_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="account-inputs"
            id="taxvat-group"
            key="taxvat"
            :label="$t('form_vat_number') + '*'"
            label-for="taxvat"
          >
            <b-form-input
              id="taxvat"
              v-model="form.taxvat"
              type="text"
              :state="form.taxvatState"
              aria-describedby="taxvat-feedback"
              @blur="validateVAT"
            ></b-form-input>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <b-form-group
          class="account-inputs"
          id="first_name-group"
          key="first_name"
          :label="$t('form_first_name') + '*'"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="form.firstname"
            type="text"
            v-on:blur="validateFirstname"
            :state="form.firstnameState"
            aria-describedby="firstname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="last_name-group"
          key="last_name"
          :label="$t('form_last_name') + '*'"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="form.lastname"
            type="text"
            v-on:blur="validateLastname"
            :state="form.lastnameState"
            aria-describedby="lastname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="country-group"
          :label="$t('form_country') + '*'"
          label-for="country"
        >
          <b-form-select
            id="country"
            v-model="form.address.country_code"
            :options="countries"
            class="select"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          class="account-inputs"
          id="postcode-group"
          key="postcode"
          :label="$t('form_postal_code') + '*'"
          label-for="postcode"
        >
          <b-form-input
            id="postcode"
            v-model="form.address.postcode"
            type="text"
            :state="form.address.postcodeState"
            aria-describedby="address.postcode-feedback"
            v-on:blur="validatePostcode"
          ></b-form-input>
          <b-form-invalid-feedback id="address.postcode-feedback">
            {{ form.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="showFullAddress"
          class="account-inputs"
          id="street-group"
          key="street"
          :label="$t('form_street') + '*'"
          label-for="street"
        >
          <b-form-input
            id="street"
            v-model="form.address.streetDisplay"
            type="text"
            v-on:blur="validateStreet"
            :state="form.address.streetDisplayState"
            aria-describedby="address.streetDisplay-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.streetDisplay-feedback">
            {{ form.address.streetDisplayError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row>
          <b-col sm="6" class="first">
            <b-form-group
              class="account-inputs"
              id="house_number-group"
              key="house_number"
              :label="$t('form_house_number') + '*'"
              label-for="house_number"
            >
              <b-form-input
                id="house_number"
                v-model="form.address.house_number"
                type="text"
                v-on:blur="validateHouseNumber"
                :state="form.address.house_numberState"
                aria-describedby="address.house_number-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="address.house_number-feedback">
                {{ form.address.house_numberError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="third">
            <b-form-group
              class="account-inputs"
              id="addition-group"
              key="addition"
              :label="$t('form_addition')"
              label-for="addition"
            >
              <b-form-input
                id="addition"
                v-model="form.address.addition"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
          v-if="showFullAddress"
          class="account-inputs"
          id="city-group"
          key="city"
          :label="$t('form_city') + '*'"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="form.address.city"
            type="text"
            v-on:blur="validateCity"
            :state="form.address.cityState"
            aria-describedby="address.city-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.city-feedback">
            {{ form.address.cityError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="foundAddress != '' && showFullAddress == false"
          class="account-inputs"
          id="add-group"
          label=" "
          label-for="add"
        >
          <div
            id="add"
            :style="{ color: hasFoundAddress === false && '#CB2E25' }"
          >
            {{ foundAddress }}
          </div>
        </b-form-group>
        <b-form-group
          v-if="foundAddresses.length > 0 && showFullAddress == false"
          class="account-inputs"
          id="addm-group"
          label=" "
          label-for="addm"
        >
          <div id="addm">
            <b-form-radio
              style="width: 300px"
              class="account-radios"
              v-model="selectedAddress"
              v-for="(item, index) in foundAddresses"
              :key="`nl-${index}`"
              name="some-radios"
              :value="index"
              >{{ item.text }}</b-form-radio
            >
          </div>
        </b-form-group>
        <b-form-checkbox
          v-if="showManual"
          id="manual"
          v-model="showFullAddress"
          name="manual"
          >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
        >
        <b-form-group
          class="account-inputs"
          id="telephone-group"
          key="telephone"
          :label="$t('form_phone') + '*'"
          label-for="telephone"
        >
          <b-form-input
            id="telephone"
            v-model="form.address.telephone"
            type="text"
            v-on:blur="validatePhone"
            :state="form.address.telephoneState"
            aria-describedby="address.telephone-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.telephone-feedback">
            {{ form.address.telephoneError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-checkbox
          id="checkbox-2"
          name="checkbox-2"
          label-for="checkbox2"
          v-model="form.address.default_billing"
          >{{ $t("form_address_also_invoice_address") }}</b-form-checkbox
        >
      </div>
    </div>
    <div class="group" v-if="form.address.default_billing == false">
      <span class="group-title"
        ><i class="fas fa-home"></i>{{ $t("invoice_address") }}</span
      >
      <div class="wrap-box">
        <b-form-checkbox
          id="checkbox-99"
          v-model="isInvoiceCompany"
          name="checkbox-99"
          >{{ $t("form_are_you_a_company") }}</b-form-checkbox
        >
        <div v-if="isInvoiceCompany" class="short-form">
          <b-form-group
            class="account-inputs"
            id="company-group"
            key="invoice-company"
            :label="$t('form_company_name') + '*'"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="form.invoice.company"
              type="text"
              v-on:blur="validateInvoiceCompany"
              :state="form.invoice.companyState"
              aria-describedby="company-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="company-feedback">
              {{ form.invoice.companyError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="coc_number-group"
            key="invoice-coc_number"
            :label="$t('form_coc_number') + '*'"
            label-for="coc_number"
          >
            <b-form-input
              id="coc_number"
              v-model="form.invoice.coc_number"
              type="text"
              v-on:blur="validateInvoiceCOCNumber"
              :state="form.invoice.coc_numberState"
              aria-describedby="coc_number-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="coc_number-feedback">
              {{ form.invoice.coc_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="account-inputs"
            id="taxvat-group"
            key="invoice-taxvat"
            :label="$t('form_vat_number') + '*'"
            label-for="taxvat"
          >
            <b-form-input
              id="invoice-taxvat"
              v-model="form.invoice.taxvat"
              type="text"
              :state="form.invoice.taxvatState"
              @blur="validateInvoiceVAT"
              aria-describedby="taxvat-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.invoice.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <b-form-group
          class="account-inputs"
          id="first_name-group"
          key="invoice-first_name"
          :label="$t('form_first_name') + '*'"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="form.invoice.firstname"
            type="text"
            v-on:blur="validateInvoiceFirstname"
            :state="form.invoice.firstnameState"
            aria-describedby="firstname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.invoice.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="last_name-group"
          key="invoice-last_name"
          :label="$t('form_last_name') + '*'"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="form.invoice.lastname"
            type="text"
            v-on:blur="validateInvoiceLastname"
            :state="form.invoice.lastnameState"
            aria-describedby="lastname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.invoice.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="country-group"
          key="invoice-country"
          :label="$t('form_country') + '*'"
          label-for="country"
        >
          <b-form-select
            id="country"
            v-model="form.invoice.address.country_code"
            :options="countries"
            class="select"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          class="account-inputs"
          id="postcode-group"
          key="invoice-postcode"
          :label="$t('form_postal_code') + '*'"
          label-for="postcode"
        >
          <b-form-input
            id="invoice-postcode"
            v-model="form.invoice.address.postcode"
            type="text"
            :state="form.invoice.address.postcodeState"
            aria-describedby="address.postcode-feedback"
            v-on:blur="validateInvoicePostcode"
          ></b-form-input>
          <b-form-invalid-feedback id="address.postcode-feedback">
            {{ form.invoice.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="showFullInvoiceAddress"
          class="account-inputs"
          id="street-group"
          key="invoice-street"
          :label="$t('form_street') + '*'"
          label-for="street"
        >
          <b-form-input
            id="street"
            v-model="form.invoice.address.streetDisplay"
            type="text"
            v-on:blur="validateInvoiceStreet"
            :state="form.invoice.address.streetDisplayState"
            aria-describedby="address.streetDisplay-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.streetDisplay-feedback">
            {{ form.invoice.address.streetDisplayError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row>
          <b-col sm="6" class="first">
            <b-form-group
              class="account-inputs"
              id="house_number-group"
              key="invoice-house_number"
              :label="$t('form_house_number') + '*'"
              label-for="invoice-house_number"
            >
              <b-form-input
                id="invoice-house_number"
                v-model="form.invoice.address.house_number"
                type="text"
                v-on:blur="validateInvoiceHouseNumber"
                :state="form.invoice.address.house_numberState"
                aria-describedby="address.house_number-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="address.house_number-feedback">
                {{ form.invoice.address.house_numberError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="third">
            <b-form-group
              class="account-inputs"
              id="addition-group"
              key="invoice-addition"
              :label="$t('form_addition')"
              label-for="addition"
            >
              <b-form-input
                id="addition"
                v-model="form.invoice.address.addition"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
          v-if="showFullInvoiceAddress"
          class="account-inputs"
          id="city-group"
          key="invoice-city-group"
          :label="$t('form_city') + '*'"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="form.invoice.address.city"
            type="text"
            v-on:blur="validateInvoiceCity"
            :state="form.invoice.address.cityState"
            aria-describedby="address.city-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.city-feedback">
            {{ form.invoice.address.cityError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="foundInvoiceAddress != '' && showFullInvoiceAddress == false"
          class="account-inputs"
          id="add-group"
          label=" "
          label-for="add"
        >
          <div
            id="add"
            :style="{ color: hasFoundInvoiceAddress === false && '#CB2E25' }"
          >
            {{ foundInvoiceAddress }}
          </div>
        </b-form-group>
        <b-form-group
          v-if="
            foundInvoiceAddresses.length > 0 && showFullInvoiceAddress == false
          "
          class="account-inputs"
          id="addm-group"
          label=" "
          label-for="addm"
        >
          <div id="addm">
            <b-form-radio
              style="width: 300px"
              class="account-radios"
              v-model="selectedInvoiceAddress"
              v-for="(item, index) in foundInvoiceAddresses"
              :key="`add-${index}`"
              name="some-radios"
              :value="index"
              >{{ item.text }}</b-form-radio
            >
          </div>
        </b-form-group>
        <b-form-checkbox
          v-if="showInvoiceManual"
          id="manual2"
          v-model="showFullInvoiceAddress"
          name="manual2"
          >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
        >
        <b-form-group
          class="account-inputs"
          id="telephone-group"
          key="invoice-telephone"
          :label="$t('form_phone') + '*'"
          label-for="telephone"
        >
          <b-form-input
            id="telephone"
            v-model="form.invoice.address.telephone"
            type="text"
            v-on:blur="validateInvoicePhone"
            :state="form.invoice.address.telephoneState"
            aria-describedby="address.telephone-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.telephone-feedback">
            {{ form.invoice.address.telephoneError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import Cart from "@storefront/core/modules/cart/mixins";
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

export default {
  name: "NotLoggedInCheckoutDetails",
  components: {
    ClientOnly,
    PasswordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
  },
  mixins: [Cart],
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }

    this.setShippingAddressData(this.shippingAddress);

    if (this.isCartLoaded == true) this.isAddressLoaded = true;
    this.storage();
    this.$root.$on("checkFields", () => {
      this.checkFields();
    });
  },

  methods: {
    setShippingAddressData(shippingAddressData) {
      if (this.guestEmail != null) {
        this.form.email = this.guestEmail;
      }
      if (shippingAddressData != null) {
        const address = shippingAddressData;

        const basicInfo = {};
        if (address.company != null) {
          this.isCompany = true;
          basicInfo.company = address.company;
        } else {
          this.isCompany = false;
        }

        if (address.vat_id != null) {
          basicInfo.taxvat = address.vat_id;
        } else {
          basicInfo.taxvat = "";
        }

        basicInfo.firstname = address.firstname;
        basicInfo.lastname = address.lastname;

        const addressInfo = {};
        addressInfo.streetDisplay = address.street[0];
        if (address.street[1] != undefined) {
          addressInfo.house_number = address.street[1];
        }
        if (address.street[2] != undefined) {
          addressInfo.addition = address.street[2];
        }
        addressInfo.postcode = address.postcode;
        addressInfo.city = address.city;
        addressInfo.telephone = address.telephone;
        addressInfo.country_code = address.country.code;

        this.form = {
          ...this.form,
          ...basicInfo,
          address: { ...this.form.address, ...addressInfo },
        };

        this.isAddressLoaded = true;
      }
    },
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.emailExists = true;
      } else {
        this.emailExists = false;
      }

      this.validateRefInput("email", this.form.emailState);
      this.form.emailState = true;
      this.updateLocalStorage({
        email: this.form.email,
        emailState: this.form.emailState,
      });
      this.$store.dispatch("cart/addCartEmail", {
        email: this.form.email,
      });
      return true;
    },
    validatePassword() {
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (this.form.password != this.form.password_confirm) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.form.password_confirmState = true;
      }
    },
    async doLogin() {
      const loginOk = await this.$store.dispatch("user/login", {
        username: this.form.email,
        password: this.form.password,
      });

      if (loginOk == true) {
        this.$root.$emit("clearLocalStorage");
        const msg = {
          type: "success",
          title: this.$t("login_success"),
          text: this.$t("you_are_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    async addAccountData() {
      let formValid = true;
      if (this.form.emailState != true) {
        formValid = false;
      }

      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.password_confirmState != true) {
        this.validateConfirmationPassword();
        if (this.form.password_confirmState != true) {
          formValid = false;
        }
      }

      if (this.isCompany == true) {
        if (this.form.company == "") {
          formValid = false;
        } else {
          if (this.form.companyState != true) {
            this.validateCompany();
            if (this.form.companyState != true) {
              formValid = false;
            }
          }
        }
        if (this.form.taxvat !== "") {
          if (this.form.taxvatState == false) {
            await this.validateVAT();
            if (this.form.taxvatState == false) {
              formValid = false;
            }
          }
        }
      }
      if (this.form.firstname == "") {
        formValid = false;
      } else {
        if (this.form.firstnameState != true) {
          this.validateFirstname();
          if (this.firstnameState != true) {
            formValid = false;
          }
        }
      }
      if (this.form.lastname == "") {
        formValid = false;
      } else {
        if (this.form.lastnameState != true) {
          this.validateLastname();
          if (this.lastnameState != true) {
            formValid = false;
          }
        }
      }

      if (this.form.address.country_code == "") {
        formValid = false;
      }

      if (this.form.address.postcode == "") {
        formValid = false;
      }

      if (this.form.address.postcode == "") {
        formValid = false;
      } else {
        if (this.form.address.postcodeState != true) {
          this.validatePostcode();
          if (this.form.address.postcodeState != true) {
            formValid = false;
          }
        }
      }
      if (this.form.address.house_number == "") {
        formValid = false;
      } else {
        if (this.form.address.house_numberState != true) {
          this.validateHouseNumber();
          if (this.form.address.house_numberState != true) {
            formValid = false;
          }
        }
      }
      if (this.form.address.streetDisplay == "") {
        formValid = false;
      } else {
        if (this.form.address.streetDisplayState != true) {
          this.validateStreet();
          if (this.form.address.streetDisplayState != true) {
            formValid = false;
            this.showFullAddress = true;
          }
        }
      }

      if (this.form.address.city == "") {
        formValid = false;
      } else {
        if (this.form.address.cityState != true) {
          this.validateCity();
          if (this.form.address.cityState != true) {
            formValid = false;
          }
        }
      }
      if (this.form.address.telephone == "") {
        formValid = false;
      } else {
        if (this.form.address.telephoneState != true) {
          this.validatePhone();
          if (this.form.address.telephoneState != true) {
            formValid = false;
          }
        }
      }

      if (formValid == true) {
        this.form.address.street = [this.form.address.streetDisplay];
        if (this.form.address.house_number != "") {
          this.form.address.street.push(this.form.address.house_number);
        }
        if (this.form.address.addition != "") {
          this.form.address.street.push(this.form.address.addition);
        }
        if (this.isCompany != true) {
          this.form.company = "";
          this.form.taxvat = "";
        }

        this.$store.dispatch("checkout/addAccountFormData", this.form);
      }
    },
    async validateVAT(event) {
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          this.form.taxvat = this.form.taxvat.replace(/[^a-zA-Z0-9]/g, "");
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.taxvat);
            if (retval.is_valid == true) {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
              this.updateLocalStorage({
                taxvat: this.form.taxvat,
                taxvatState: this.form.taxvatState,
              });
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
      }

      if (event) {
        this.validateInput(event, this.form.taxvatState);
      } else {
        this.validateRefInput("taxvat", this.form.taxvatState);
      }
    },
    async validateAddress() {
      let isOk = true;
      if (this.isCompany == true) {
        if (this.form.company == "") {
          isOk = false;
        } else {
          if (this.form.companyState != true) {
            this.validateCompany();
            if (this.form.companyState != true) {
              isOk = false;
            }
          }
        }
        if (this.form.taxvat !== "") {
          if (this.form.taxvatState == false) {
            await this.validateVAT();
            if (this.form.taxvatState == false) {
              isOk = false;
            }
          }
        }
      }
      if (this.form.firstname == "") {
        isOk = false;
      } else {
        if (this.form.firstnameState != true) {
          this.validateFirstname();
          if (this.firstnameState != true) {
            isOk = false;
          }
        }
      }

      if (this.form.lastname == "") {
        isOk = false;
      } else {
        if (this.form.lastnameState != true) {
          this.validateLastname();
          if (this.lastnameState != true) {
            isOk = false;
          }
        }
      }

      if (this.form.address.country_code == "") {
        isOk = false;
      }

      if (this.form.address.postcode == "") {
        isOk = false;
      }
      if (this.form.address.postcode != "") {
        if (this.form.address.postcodeState != true) {
          this.validatePostcode();
          if (this.form.address.postcodeState != true) {
            isOk = false;
          }
        }
      }

      if (this.form.address.streetDisplay == "") {
        isOk = false;
      } else {
        if (this.form.address.streetDisplayState != true) {
          this.validateStreet();
          if (this.form.address.streetDisplayState != true) {
            isOk = false;
            this.showFullAddress = true;
          }
        }
      }

      if (this.form.address.house_number == "") {
        isOk = false;
      } else {
        if (this.form.address.house_numberState != true) {
          this.validateHouseNumber();
          if (this.form.address.house_numberState != true) {
            isOk = false;
          }
        }
      }

      if (this.form.address.city == "") {
        isOk = false;
      } else {
        if (this.form.address.cityState != true) {
          this.validateCity();
          if (this.form.address.cityState != true) {
            isOk = false;
            this.showFullAddress = true;
          }
        }
      }

      if (this.form.address.telephone == "") {
        isOk = false;
      } else {
        if (this.form.address.telephoneState != true) {
          this.validatePhone();
          if (this.form.address.telephoneState != true) {
            isOk = false;
          }
        }
      }

      if (isOk == false) {
        this.removeShippingMethod();
      } else {
        if (this.form.emailState == null) {
          this.form.emailState = false;
          this.removeShippingMethod();
          return;
        }

        if (this.isCreateAccount == true) {
          await this.addAccountData();
        }
        const address = {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          postcode: this.form.address.postcode,
          city: this.form.address.city,
          telephone: this.form.address.telephone,
          street: [
            this.form.address.streetDisplay,
            this.form.address.house_number,
          ],
          country_code: this.form.address.country_code,
        };

        if (this.form.address.addition != "") {
          address.street.push(this.form.address.addition);
        }

        if (this.isCompany == true) {
          address.company = this.form.company;
          if (this.form.taxvat != "") {
            address.vat_id = this.form.taxvat;
          }
        } else {
          address.company = "";
          address.vat_id = "";
        }
        await this.$store.dispatch("cart/addShippingAddress", {
          address: address,
        });
        if (this.form.address.default_billing == true) {
          await this.$store.dispatch("cart/addBillingAddress", {
            address: address,
          });
        }
      }
    },
    async validateInvoiceAddress() {
      let isOk = true;
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          isOk = false;
        }
        if (this.form.invoice.taxvat !== "") {
          if (this.form.invoice.taxvatState != true) {
            await this.validateInvoiceVAT();
            if (this.form.invoice.taxvatState != true) {
              isOk = false;
            }
          }
        }
      }
      if (this.form.invoice.firstname == "") {
        isOk = false;
      }
      if (this.form.invoice.lastname == "") {
        isOk = false;
      }
      if (this.form.invoice.address.country_code == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode != "") {
        if (this.form.invoice.address.postcodeState != true) {
          this.validateInvoicePostcode();
          if (this.form.invoice.address.postcodeState != true) {
            isOk = false;
          }
        }
      }
      if (this.form.invoice.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.invoice.address.house_number == "") {
        isOk = false;
      }
      if (this.form.invoice.address.city == "") {
        isOk = false;
      }
      if (this.form.invoice.address.telephone == "") {
        isOk = false;
      }

      if (isOk == true) {
        const address = {
          company: this.form.invoice.company,
          firstname: this.form.invoice.firstname,
          lastname: this.form.invoice.lastname,
          postcode: this.form.invoice.address.postcode,
          city: this.form.invoice.address.city,
          telephone: this.form.invoice.address.telephone,
          street: [
            this.form.invoice.address.streetDisplay,
            this.form.invoice.address.house_number,
          ],
          country_code: this.form.invoice.address.country_code,
        };

        if (this.form.invoice.address.addition != "") {
          address.street.push(this.form.invoice.address.addition);
        }

        if (this.form.invoice.isCompany == true) {
          address.company = this.form.invoice.company;
          if (this.form.invoice.taxvat != "") {
            address.vat_id = this.form.invoice.taxvat;
          }
        } else {
          address.company = "";
          address.vat_id = "";
        }

        this.$store.dispatch("cart/addBillingAddress", { address: address });
      }
    },
    validateCompany(event) {
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
          this.updateLocalStorage({
            company: this.form.company,
            companyState: this.form.companyState,
          });
          if (this.form.address.default_billing) {
            this.updateLocalStorage({
              invoiceCompany: this.form.company,
              invoiceCompanyState: this.form.companyState,
            });
          }
        }
      } else {
        this.form.companyState = null;
      }
      this.validateInput(event, this.form.companyState);
    },
    validateFirstname(event) {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
        this.updateLocalStorage({
          firstname: this.form.firstname,
          firstnameState: this.form.firstnameState,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceFirstname: this.form.firstname,
            invoiceFirstnameState: this.form.firstnameState,
          });
        }
      }
      this.validateInput(event, this.form.firstnameState);
    },
    validateLastname(event) {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
        this.updateLocalStorage({
          lastname: this.form.lastname,
          lastnameState: this.form.lastnameState,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceLastname: this.form.lastname,
            invoiceLastnameState: this.form.lastnameState,
          });
        }
      }
      this.validateInput(event, this.form.lastnameState);
    },
    validateCOCNumber(event) {
      if (this.isCompany == true) {
        if (this.form.coc_number == "") {
          this.form.coc_numberState = false;
          this.form.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.coc_numberState = true;
          this.updateLocalStorage({
            coc: this.form.coc_number,
            cocState: this.form.coc_numberState,
          });
          if (this.form.address.default_billing) {
            this.updateLocalStorage({
              invoiceCoc: this.form.coc_number,
              invoiceCocState: this.form.coc_numberState,
            });
          }
        }
      } else {
        this.form.coc_numberState = null;
      }
      this.validateInput(event, this.form.coc_numberState);
      this.updateLocalStorage({
        coc_number: this.form.coc_number,
        coc_numberState: this.form.coc_numberState,
      });
      if (this.form.address.default_billing) {
        this.updateLocalStorage({
          invoice_coc_number: this.form.coc_number,
          invoice_coc_numberState: this.form.coc_numberState,
        });
      }
    },
    validateStreet(event) {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceAddressData: this.form.invoice.address,
            selectedInvoiceAddress: this.selectedAddress,
          });
        }
      }
      this.validateInput(event, this.form.address.streetDisplayState);
    },
    validateHouseNumber(event) {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        if (this.hasFoundAddress !== false) {
          this.form.address.house_numberState = true;
          this.updateLocalStorage({
            addressData: this.form.address,
            selectedAddress: this.selectedAddress,
          });
          if (this.form.address.default_billing) {
            this.updateLocalStorage({
              invoiceAddressData: this.form.invoice.address,
              selectedInvoiceAddress: this.selectedAddress,
            });
          }
        } else {
          this.form.address.house_numberState = null;
        }
      }
      this.validateInput(event, this.form.address.house_numberState);
    },
    validateCity(event) {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
        this.updateLocalStorage({
          addressData: this.form.address,
          address: this.shi,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceAddressData: this.form.address,
            invoiceAddress: this.shippingAddress,
          });
        }
      }
      this.validateInput(event, this.form.address.cityState);
    },
    validatePhone(event) {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
        this.updateLocalStorage({
          addressData: this.form.address,
          address: this.shippingAddress,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceAddressData: this.form.address,
            invoiceAddress: this.shippingAddress,
          });
        }
      }
      this.validateInput(event, this.form.address.telephoneState);
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            let addition = list[0].letter;
            if (list[0].addition != null) {
              addition += list[0].addition;
            }
            this.form.address.addition = addition;
            address += address + addition;
          } else {
            this.form.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState == true;
          this.foundAddress = address;
          this.hasFoundAddress = true;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.hasFoundAddress = false;
          this.foundAddress = this.$t("postcode_not_found");

          this.form.address.streetDisplay = "";
          this.form.address.streetDisplayState = false;
          this.form.address.streetDisplayError = this.$t("street_required");

          this.form.address.city = "";
          this.form.address.cityState = false;
          this.form.address.cityError = this.$t("city_required");
        } else {
          this.hasFoundAddress = true;
          list.forEach(function (item) {
            if (this.form.address.addition == "") {
              let address = item.street + " " + item.number;
              if (item.letter != null) {
                address = address + item.letter;
              }
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              this.foundAddresses.push(item);
              this.foundAddresses.sort((a, b) => {
                if (a.letter > b.letter) return 1;
                if (a.letter < b.letter) return -1;
                return 0;
              });
            } else {
              if (
                item.letter != null &&
                item.letter.toUpperCase() ==
                  this.form.address.addition.toUpperCase()
              ) {
                let address = item.street + " " + item.number;
                address = address + item.letter;
                address = address + ", " + item.postcode + " " + item.city;
                item.text = address;
                this.form.address.streetDisplay = item.street;
                this.foundAddress = address;
                this.form.address.city = item.city;
                this.form.address.cityState = true;
                this.foundAddress = address;
                this.validatePostcode();
                return;
              }
            }
          }, this);
          if (
            this.foundAddresses.length == 0 &&
            this.foundAddress.length == 0
          ) {
            this.form.address.streetDisplay = "";
            this.form.address.streetDisplayState = false;
            this.form.address.city = "";
            this.form.address.cityState = false;
            this.foundAddress = this.$t("postcode_not_found");
            this.form.address.streetDisplayError = this.$t("street_required");
            this.form.address.cityError = this.$t("city_required");
          }
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
          this.updateLocalStorage({
            addressData: this.form.address,
            address: this.shippingAddress,
          });
          if (this.form.address.default_billing) {
            this.updateLocalStorage({
              invoiceAddressData: this.form.address,
              invoiceAddress: this.shippingAddress,
            });
          }
        }
      }
      this.validateAddress();
    },
    validatePostcode(event) {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.address.country_code == "NL" &&
        nl.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "BE" &&
        be.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
        this.updateLocalStorage({
          addressData: this.form.address,
          selectedAddress: this.selectedAddress,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceAddressData: this.form.invoice.address,
            selectedInvoiceAddress: this.selectedAddress,
          });
        }
      } else if (
        this.form.address.country_code == "DE" &&
        de.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
        this.updateLocalStorage({
          addressData: this.form.address,
          selectedAddress: this.selectedAddress,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceAddressData: this.form.invoice.address,
            selectedInvoiceAddress: this.selectedAddress,
          });
        }
      } else {
        this.form.address.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      }
      if (this.hasFoundAddress !== false) {
        if (event) {
          this.validateInput(event, this.form.address.postcodeState);
        } else {
          this.validateRefInput("postcode", this.form.address.postcodeState);
        }
      } else {
        this.form.address.postcodeState = null;
        this.validateRefInput("postcode", this.form.address.postcodeState);
      }
      if (
        this.form.address.country_code !== "NL" &&
        this.form.address.postcodeState == true
      ) {
        this.validateAddress();
      }
    },
    async validateInvoiceVAT(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.taxvat != "") {
          this.form.taxvat = this.form.taxvat.replace(/[^a-zA-Z0-9]/g, "");
          if (
            this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
            this.form.invoice.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.invoice.taxvat);
            if (retval.is_valid == true) {
              this.isValidInvoiceVatNumber = true;
              this.form.invoice.taxvatState = true;
              this.updateLocalStorage({
                invoiceVat: this.form.invoice.taxvat,
                invoiceVatState: this.form.invoice.taxvatState,
              });
            } else {
              this.isValidInvoiceVatNumber = false;
              this.form.invoice.taxvatState = false;
              this.form.invoice.taxvatError = this.$t("taxvat_invalid");

              this.showInvoiceVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidInvoiceVatNumber = false;
            this.form.invoice.taxvatState = false;
            this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.invoice.taxvatState = null;
      }
      if (event) {
        this.validateInput(event, this.form.invoice.taxvatState);
      } else {
        this.validateRefInput("invoice-taxvat", this.form.invoice.taxvatState);
      }
    },
    validateInvoiceCompany(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          this.form.invoice.companyState = false;
          this.form.invoice.companyError = this.$t("company_name_required");
        } else {
          this.form.invoice.companyState = true;
          this.updateLocalStorage({
            invoiceFirstName: this.form.invoice.company,
            invoiceFirstNameState: this.form.invoice.company,
          });
        }
      } else {
        this.form.invoice.companyState = null;
      }
      this.validateInput(event, this.form.invoice.companyState);
    },
    validateInvoiceFirstname(event) {
      if (this.form.invoice.firstname == "") {
        this.form.invoice.firstnameState = false;
        this.form.invoice.firstnameError = this.$t("firstname_required");
      } else {
        this.form.invoice.firstnameState = true;
        this.updateLocalStorage({
          invoiceFirstName: this.form.invoice.firstname,
          invoiceFirstNameState: this.form.invoice.firstnameState,
        });
      }
      this.validateInput(event, this.form.invoice.firstnameState);
    },
    validateInvoiceLastname(event) {
      if (this.form.invoice.lastname == "") {
        this.form.invoice.lastnameState = false;
        this.form.invoice.lastnameError = this.$t("lastname_required");
      } else {
        this.form.invoice.lastnameState = true;
        this.updateLocalStorage({
          invoiceLastName: this.form.invoice.lastname,
          invoiceLastnameState: this.form.invoice.lastnameState,
        });
      }
      this.validateInput(event, this.form.invoice.lastnameState);
    },
    validateInvoiceCOCNumber(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.coc_number == "") {
          this.form.invoice.coc_numberState = false;
          this.form.invoice.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.invoice.coc_numberState = true;
          this.updateLocalStorage({
            invoiceCoc: this.form.invoice.coc_number,
            invoiceCocState: this.form.invoice.coc_numberState,
          });
        }
      } else {
        this.form.invoice.coc_numberState = null;
      }
      this.validateInput(event, this.form.invoice.coc_numberError);
      this.updateLocalStorage({
        invoice_coc_number: this.form.invoice.coc_number,
        invoice_coc_numberState: this.form.invoice.coc_numberState,
      });
    },
    validateInvoiceStreet(event) {
      if (this.form.invoice.address.streetDisplay == "") {
        this.form.invoice.address.streetDisplayState = false;
        this.form.invoice.address.streetDisplayError =
          this.$t("street_required");
      } else {
        this.form.invoice.address.streetDisplayState = true;
        this.updateLocalStorage({
          invoiceAddressData: this.form.invoice.address,
        });
      }
      this.validateInput(event, this.form.invoice.address.streetDisplayState);
    },
    validateInvoiceHouseNumber(event) {
      if (this.form.invoice.address.house_number == "") {
        this.form.invoice.address.house_numberState = false;
        this.form.invoice.address.house_numberError = this.$t(
          "house_number_required"
        );
      } else {
        if (this.hasFoundInvoiceAddress !== false) {
          this.form.invoice.address.house_numberState = true;
          this.updateLocalStorage({
            invoiceAddressData: this.form.invoice.address,
          });
        } else {
          this.form.invoice.address.house_numberState = null;
        }
      }
      this.validateInput(event, this.form.invoice.address.house_numberState);
    },
    validateInvoiceCity(event) {
      if (this.form.invoice.address.city == "") {
        this.form.invoice.address.cityState = false;
        this.form.invoice.address.cityError = this.$t("city_required");
      } else {
        this.form.invoice.address.cityState = true;
        this.updateLocalStorage({
          invoiceAddressData: this.form.invoice.address,
        });
      }
      this.validateInput(event, this.form.invoice.address.cityState);
    },
    validateInvoicePhone(event) {
      if (
        this.form.invoice.address.telephone.substring(0, 3) == "+31" ||
        (this.form.invoice.address.country_code == "NL" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          this.form.invoice.address.telephone.match(this.regExNlPhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_nl_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
          this.updateLocalStorage({
            invoiceAddressData: this.form.invoice.address,
          });
        }
      } else if (
        this.form.invoice.address.telephone.substring(0, 3) == "+32" ||
        (this.form.invoice.address.country_code == "BE" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          !this.form.invoice.address.telephone.match(this.regExBePhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_be_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
          this.updateLocalStorage({
            invoiceAddressData: this.form.invoice.address,
          });
        }
      } else if (
        this.form.invoice.address.telephone.substring(0, 3) == "+49" ||
        (this.form.invoice.address.country_code == "DE" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          !this.form.invoice.address.telephone.match(this.regExDePhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_de_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
          this.updateLocalStorage({
            invoiceAddressData: this.form.invoice.address,
          });
        }
      } else if (
        this.form.invoice.address.telephone.match(this.regExGenPhone) == null
      ) {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.invoice.address.telephoneState = true;
        this.updateLocalStorage({
          invoiceAddressData: this.form.invoice.address,
        });
      }

      this.validateInput(event, this.form.invoice.address.telephoneState);
    },
    async validateInvoicePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.invoice.address.postcode,
          this.form.invoice.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.invoice.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.invoice.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.invoice.address.city = list[0].city;
          this.form.invoice.address.cityState == true;
          this.foundAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
        } else {
          this.foundAddresses = [];
          list.forEach(function (item) {
            if (this.form.invoice.address.addition == "") {
              let address = item.street + " " + item.number;
              if (item.letter != null) {
                address = address + item.letter;
              }
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              this.foundAddresses.push(item);
              this.foundAddresses.sort((a, b) => {
                if (a.letter > b.letter) return 1;
                if (a.letter < b.letter) return -1;
                return 0;
              });
            } else {
              if (
                item.letter != null &&
                item.letter.toUpperCase() ==
                  this.form.invoice.address.addition.toUpperCase()
              ) {
                let address = item.street + " " + item.number;
                address = address + item.letter;
                address = address + ", " + item.postcode + " " + item.city;
                item.text = address;
                this.form.invoice.address.streetDisplay = item.street;
                this.foundAddress = address;
                this.form.invoice.address.city = item.city;
                this.form.invoice.address.cityState == true;
                this.foundAddress = address;
                this.validatePostcode();
                return;
              }
            }
          }, this);
          if (
            this.foundAddresses.length == 0 &&
            this.foundAddress.length == 0
          ) {
            this.form.invoice.address.streetDisplay = "";
            this.form.invoice.address.streetDisplayState = null;
            this.form.invoice.address.city = "";
            this.form.invoice.address.cityState == null;
            this.foundAddress = this.$t("postcode_not_found");
          }
        }
      } else {
        if (this.form.invoice.address.postcode == "") {
          this.form.invoice.address.postcodeState = false;
          this.form.invoice.address.postcodeError =
            this.$t("postcode_required");
        } else {
          this.form.invoice.address.postcodeState = true;
          this.updateLocalStorage({
            invoiceAddressData: this.form.invoice.address,
          });
        }
      }
      this.validateInvoiceAddress();
    },
    validateInvoicePostcode(event) {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.invoice.address.country_code == "NL" &&
        nl.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
        this.updateLocalStorage({
          invoiceAddressData: this.form.invoice.address,
        });
      } else if (
        this.form.invoice.address.country_code == "BE" &&
        be.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
        this.updateLocalStorage({
          invoiceAddressData: this.form.invoice.address,
        });
      } else if (
        this.form.invoice.address.country_code == "DE" &&
        de.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
        this.updateLocalStorage({
          invoiceAddressData: this.form.invoice.address,
        });
      } else {
        this.form.invoice.address.postcodeState = false;
        this.form.invoice.address.postcodeError = this.$t("postcode_required");
      }
      if (this.hasFoundInvoiceAddress !== false) {
        if (event) {
          this.validateInput(event, this.form.invoice.address.postcodeState);
        } else {
          this.validateRefInput(
            "invoice-postcode",
            this.form.invoice.address.postcodeState
          );
        }
      } else {
        this.form.invoice.address.postcodeState = null;
        this.validateRefInput(
          "invoice-postcode",
          this.form.invoice.address.postcodeState
        );
      }
      if (
        this.form.invoice.address.country_code !== "NL" &&
        this.form.invoice.address.postcodeState == true
      ) {
        this.validateInvoiceAddress();
      }
    },
    validateInput(event, isValid) {
      if (typeof event !== "undefined") {
        if (isValid == true) {
          event.target.parentElement.classList.add("valid");
        } else {
          event.target.parentElement.classList.remove("valid");
        }
      }
    },
    validateRefInput(id, isValid) {
      const element = document.getElementById(id);
      if (element) {
        if (isValid == true) {
          element.parentElement.classList.add("valid");
        } else {
          element.parentElement.classList.remove("valid");
        }
      }
    },
    removeShippingMethod() {
      this.$store.commit("cart/setShippingMethods", []);
      this.$store.commit("cart/setShippingMethod", null);
    },
    storage() {
      this.localStorageData =
        JSON.parse(localStorage.getItem("checkoutAddress")) ?? {};
      this.form.email = this.localStorageData.email
        ? this.localStorageData.email
        : "";
      this.form.firstname = this.localStorageData.firstname
        ? this.localStorageData.firstname
        : "";
      this.form.lastname = this.localStorageData.lastname
        ? this.localStorageData.lastname
        : "";
      this.form.firstnameState = this.localStorageData.firstnameState
        ? this.localStorageData.firstnameState
        : "";
      this.form.lastnameState = this.localStorageData.lastnameState
        ? this.localStorageData.lastnameState
        : "";
      this.form.address = this.localStorageData.addressData
        ? this.localStorageData.addressData
        : {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          };
      this.form.invoice.address = this.localStorageData.invoiceAddressData
        ? this.localStorageData.invoiceAddressData
        : {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          };
      this.shippingAddress = this.localStorageData.address;
      this.invoiceAddress = this.localStorageData.invoiceAddress;
    },
    clearLocalStorage() {
      localStorage.removeItem("checkoutAddress");
    },
    updateLocalStorage(data) {
      this.localStorageData = Object.assign({}, this.localStorageData, data);
      localStorage.setItem(
        "checkoutAddress",
        JSON.stringify(this.localStorageData)
      );
    },
    checkFields() {
      var form = this.form;
      var errorFields = [];
      var skipFields = [
        "taxvat",
        "streetDisplay",
        "password",
        "password_confirm",
        "coc_number",
      ];
      Object.keys(form).forEach((name) => {
        if (
          typeof form[name] == "object" &&
          !Array.isArray(form[name]) &&
          form[name] !== null
        ) {
          if (name == "invoice" && form.address.default_billing) {
            return;
          }
          Object.keys(form[name]).forEach((child) => {
            if (child == "companyState" && name == "address") {
              if (!this.isCompany) {
                return;
              }
            }
            if (child == "companyState" && name == "invoice") {
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (
              child.includes("State") &&
              (form[name][child] == false || form[name][child] == null)
            ) {
              if (child == "companyState" && name == "address") {
                if (!this.isCompany) {
                  return;
                }
              }
              if (child == "companyState" && name == "invoice") {
                if (!this.isInvoiceCompany) {
                  return;
                }
              }
              if (skipFields.includes(child.replace("State", ""))) {
                return;
              }
              switch (name) {
                case "invoice":
                  child = "invoice_" + child;
                  break;
                case "address":
                  child = "shipping_" + child;
                  break;
              }
              errorFields.push(this.$t(child.replace("State", "")));
            }
          });
        } else {
          if (
            name.includes("State") &&
            (form[name] == false || form[name] == null)
          ) {
            if (name == "companyState") {
              if (!this.isCompany) {
                return;
              }
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (
              (name == "passwordState" || name == "password_confirmState") &&
              (!form.emailState || !this.emailExists)
            ) {
              return;
            }
            if (skipFields.includes(name.replace("State", ""))) {
              return;
            }
            errorFields.push(this.$t(name.replace("State", "")));
          }
        }
        if (errorFields.length > 0) {
          this.$store.commit("cart/setStoreValidation", {
            address: errorFields,
          });
        } else {
          this.$store.commit("cart/setStoreValidation", {
            address: [],
          });
        }
      });
    },
  },
  computed: {
    guestEmail() {
      return this.$store.getters["cart/getEmail"];
    },
    isCartLoaded() {
      return this.$store.getters["cart/getCartIsLoaded"];
    },
    shippingAddress: {
      get() {
        return this.$store.getters["cart/getShippingAddress"];
      },
      set() {},
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    address() {
      return `${this.isCompany}|${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.address.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.addition}|${this.form.address.telephone}||${this.form.address.city}`;
    },
    invoiceAddress: {
      get() {
        return `${this.isInvoiceCompany}|${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
      },
      set() {},
    },
    invoiceCountry() {
      return this.form.invoice.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}|${this.form.address.addition}`;
    },
    invoicePostcodeHouseNumber() {
      return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    invoiceVatNumber() {
      return this.form.invoice.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    isCreateAccount: {
      get() {
        return this.$store.getters["checkout/getCreateAccount"];
      },
      set(value) {
        this.$store.dispatch("checkout/changeCreateAccount", value);
      },
    },
  },
  watch: {
    shippingAddress(v) {
      if (this.isAddressLoaded != true) {
        this.setShippingAddressData(v);
      }
    },
    country() {
      if (config.postcode_validation.enabled) {
        if (!config.postcode_validation.countries.includes(this.country)) {
          this.showFullAddress = true;
          this.showManual = false;
        } else {
          this.showFullAddress = false;
          this.showManual = true;
        }
      } else {
        this.showFullAddress = true;
        this.showManual = false;
      }
      if (this.form.address.postcode.length > 0) {
        this.validatePostcode();
      }
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          this.validateVAT();
        }
      }
    },
    address() {
      if (this.form.addressTimer !== null) {
        clearTimeout(this.form.addressTimer);
      }
      this.form.addressTimer = setTimeout(
        function (scope) {
          scope.validateAddress();
        },
        1000,
        this
      );
    },
    invoiceAddress() {
      if (this.form.invoiceAddressTimer !== null) {
        clearTimeout(this.form.invoiceAddressTimer);
      }
      this.form.invoiceAddressTimer = setTimeout(
        function (scope) {
          scope.validateInvoiceAddress();
        },
        1000,
        this
      );
    },
    invoiceCountry() {
      if (config.postcode_validation.enabled) {
        if (
          !config.postcode_validation.countries.includes(this.invoiceCountry)
        ) {
          this.showFullInvoiceAddress = true;
          this.showInvoiceManual = false;
        } else {
          this.showFullInvoiceAddress = false;
          this.showInvoiceManual = true;
        }
      } else {
        this.showFullInvoiceAddress = true;
        this.showInvoiceManual = false;
      }
      if (this.form.invoice.address.postcode.length > 0) {
        this.validateInvoicePostcode();
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    invoiceVatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.invoice.taxvatTimer !== null) {
          clearTimeout(this.form.invoice.taxvatTimer);
        }
        this.form.invoice.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateInvoiceVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        this.hasFoundAddress = null;
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        } else {
          this.validateAddress();
        }
      }
    },
    invoicePostcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundInvoiceAddress != "") {
          this.foundInvoiceAddress = "";
        }
        if (this.foundInvoiceAddresses.length > 0) {
          this.foundInvoiceAddresses = [];
        }
        this.hasFoundInvoiceAddress = null;
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validateInvoicePostcodeHouseNumber();
            },
            2000,
            this
          );
        } else {
          this.validateInvoiceAddress();
        }
      }
    },
    selectedAddress(newVal) {
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;

      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
    selectedInvoiceAddress(newVal) {
      this.form.invoice.address.streetDisplay =
        this.foundInvoiceAddresses[newVal].street;
      if (this.foundInvoiceAddresses[newVal].letter != null) {
        this.form.invoice.address.addition =
          this.foundInvoiceAddresses[newVal].letter;
      } else {
        this.form.invoice.address.addition = "";
      }
      this.form.invoice.address.city = this.foundAddresses[newVal].city;
      this.validateInvoicePostcode();
    },
    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
    showFullAddress(newVal) {
      if (newVal == false) {
        this.validatePostcodeHouseNumber();
      }
    },
    hasFoundAddress(newVal) {
      if (newVal == false) {
        this.form.address.postcodeState = null;
        this.validateRefInput("postcode", this.form.address.postcodeState);
        this.form.address.house_numberState = null;
        this.validateRefInput(
          "house_number",
          this.form.address.house_numberState
        );
      }
    },
    showFullInvoiceAddress(newVal) {
      if (newVal == false) {
        this.validateInvoicePostcodeHouseNumber();
      }
    },
    hasFoundInvoiceAddress(newVal) {
      if (newVal == false) {
        this.form.invoice.address.postcodeState = null;
        this.validateRefInput(
          "invoice-postcode",
          this.form.invoice.address.postcodeState
        );
        this.form.invoice.address.house_numberState = null;
        this.validateRefInput(
          "invoice-house_number",
          this.form.invoice.address.house_numberState
        );
      }
    },
    isCompany(newVal) {
      if (newVal == false) {
        this.form.company = "";
        this.form.companyState = null;
        this.form.coc_number = "";
        this.form.coc_numberState = null;
        this.form.taxvat = "";
        this.form.taxvatState = null;
      }
    },
    isInvoiceCompany(newVal) {
      if (newVal == false) {
        this.form.invoice.company = "";
        this.form.invoice.companyState = null;
        this.form.invoice.coc_number = "";
        this.form.invoice.coc_numberState = null;
        this.form.invoice.taxvat = "";
        this.form.invoice.taxvatState = null;
      }
    },
  },
  data() {
    return {
      couponCode: "",
      isCompany: false,
      isInvoiceCompany: false,
      isValidVatNumber: false,
      isValidInvoiceVatNumber: false,
      showFullAddress: false,
      showFullInvoiceAddress: false,
      showVatMessage: "",
      showInvoiceVatMessage: "",
      showVatCheck: false,
      addressTimer: null,
      invoiceAddressTimer: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showManual: true,
      showInvoiceManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      hasFoundAddress: null,
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      hasFoundInvoiceAddress: null,
      emailExists: null,
      selectedAddress: null,
      selectedInvoiceAddress: null,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        coc_number: "",
        coc_numberState: null,
        coc_numberError: "",
        coc_numberTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: {
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: [],
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        invoice: {
          isCompany: true,

          company: "",
          companyState: null,
          companyError: "",
          companyTimer: null,
          firstname: "",
          firstnameState: null,
          firstnameError: "",
          firstnameTimer: null,
          lastname: "",
          lastnameState: null,
          lastnameError: "",
          lastnameTimer: null,
          coc_number: "",
          coc_numberState: null,
          coc_numberError: "",
          coc_numberTimer: null,
          taxvat: "",
          taxvatState: null,
          taxvatError: "",
          taxvatTimer: null,
          address: {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: [],
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          },
        },
        is_subscribed: false,
        checkbox2: false,
        manual: false,
      },
      isAddressLoaded: false,
      localStorageData: {},
    };
  },
};
</script>

<style lang="scss" scoped></style>
