<template>
  <div class="second-col">
    <div class="group">
      <span class="group-title"
        ><i class="fas fa-truck-moving"></i>{{ $t("shipping_method") }}</span
      >
      <div v-if="deliveryOptions.length == 0" class="note">
        {{ $t("fill_address_information") }}
      </div>
      <ul class="delivery-options-list list-unstyled pl-0 m-0">
        <li
          v-for="(deliveryOption, index) of deliveryOptions"
          :key="`ship-${index}`"
        >
          <b-form-radio
            class="pl-30"
            v-model="selectedShippingMethod"
            name="deliveryOption"
            :value="`${deliveryOption.carrier_code}|${deliveryOption.method_code}`"
          >
            <div class="delivery-price-wrap">
              <span class="price">{{
                formatCurrency(deliveryOption.amount.value)
              }}</span>
              <span class="lable">{{ deliveryOption.method_title }}</span>
            </div>
          </b-form-radio>
        </li>
      </ul>
    </div>
    <div class="group">
      <span class="group-title"
        ><i class="fas fa-credit-card"></i>{{ $t("payment_method") }}</span
      >

      <div v-if="shippingMethod == null" class="note">
        {{ $t("fill_shipping_options") }}
      </div>
      <div v-else>
        <ul class="payment-options-list">
          <li
            v-for="(paymentOption, index) in paymentOptions"
            :key="`pay-${index}`"
          >
            <b-form-radio
              class=""
              name="paymentOption"
              :value="paymentOption.code"
              v-on:change="checkAllSubOptions(paymentOption.code)"
              v-model="selectedPaymentMethod"
            >
              <div class="d-flex align-items-center">
                <div class="payment-option-img">
                  <img
                    :src="`${app_url}/media/graphql/icons/50x32/${paymentOption.code}.png`"
                    :alt="paymentOption.code"
                  />
                </div>
                <span>{{ paymentOption.label }}</span>
              </div>
            </b-form-radio>
            <div
              :class="showPaymentSuboptions"
              v-for="(
                paymentSubOption, index
              ) in paymentOption.additional_fields"
              :key="`pso-${index}`"
            >
              <div v-if="paymentSubOption.type == 'select'" class="bank-select">
                <span class="d-block mb-15">{{ paymentSubOption.label }}</span>
                <b-form-group class="select--custom">
                  <b-form-select
                    :options="paymentSubOption.options"
                    text-field="label"
                    :value="
                      paymentMethodOptions[paymentOption.code][
                        paymentSubOption.code
                      ]
                    "
                    v-on:change="
                      setPaymentMethodOptions(
                        paymentOption.code,
                        paymentSubOption.code,
                        $event
                      )
                    "
                    v-model="selectedIssuer"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import Cart from "@storefront/core/modules/cart/mixins";
import { isAndroid, isIOS, isMacOs } from "mobile-device-detect";

export default {
  name: "CheckoutPayment",
  mixins: [Cart],
  computed: {
    deliveryOptions() {
      return this.$store.getters["cart/getShippingMethods"];
    },
    paymentOptions: {
      get() {
        var options = this.$store.getters["cart/getPaymentMethods"];
        let newOptions = [];
        if (options.length > 0) {
          options.forEach((v) => {
            if (/applepay$/.test(v.code)) {
              if (isIOS || isMacOs) {
                newOptions.push(v);
              }
            } else if (/googlepay$/.test(v.code)) {
              if (isAndroid) {
                newOptions.push(v);
              }
            } else {
              newOptions.push(v);
            }
          });
          return newOptions;
        } else {
          return options;
        }
      },
      set() {},
    },
    paymentMethodOptions() {
      return this.$store.getters["cart/getPaymentMethodOptions"];
    },
    cartTotalWithOutTax: function () {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_excluding_tax != "undefined") {
            if (typeof prices.subtotal_excluding_tax.value != "undefined") {
              return prices.subtotal_excluding_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    getSelectedShippingMethod() {
      const shmed = this.$store.getters["cart/getShippingMethod"];
      this.updateLocalStorage({
        shipping: shmed.carrier_code + "|" + shmed.method_code,
        delivery: null,
      });
      return shmed;
    },
    shippingMethod: {
      get() {
        return this.$store.getters["cart/getShippingMethod"];
      },
      set(shippingMethod) {
        return shippingMethod;
      },
    },
    selectedShippingMethod: {
      get: function () {
        const shmed = this.$store.getters["cart/getShippingMethod"];
        if (shmed != null) {
          return shmed.carrier_code + "|" + shmed.method_code;
        } else {
          return null;
        }
      },
      set: function (value) {
        const delOption = value.split("|");
        this.updateLocalStorage({
          shipping: this.selectedShippingMethod,
          delivery: null,
        });
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
        if (this.$gtm.enabled()) {
          let prod = [];
          let items = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            let it = {
              item_id: element.product.sku,
              item_name: element.product.name,
              quantity: element.quantity,
              price: element.prices.price.value,
            };
            prod.push(i);
            items.push(it);
          });
          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "eec.checkout",
              ecommerce: {
                checkout: {
                  actionField: {
                    step: 3,
                    option: value,
                    action: "checkout",
                  },
                  products: prod,
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "add_shipping_info",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: this.cartTotalWithOutTax,
                  shipping_tier: value,
                  items: items,
                },
              },
            });
          }
        }
      },
    },
    getSelectedPaymentMethod() {
      const paymed = this.$store.getters["cart/getPaymentMethod"];
      return paymed;
    },
    selectedPaymentMethod: {
      get: function () {
        var paymentMethod = this.$store.getters["cart/getPaymentMethod"];
        if (!paymentMethod && this.localStorageData.billing) {
          paymentMethod = this.localStorageData.billing;
        }
        return paymentMethod;
      },
      set: function (method) {
        this.updateLocalStorage({ billing: method });
        this.$store.commit("cart/setPaymentMethod", method);
        if (this.$gtm.enabled()) {
          let prod = [];
          let items = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            let it = {
              item_id: element.product.sku,
              item_name: element.product.name,
              quantity: element.quantity,
              price: element.prices.price.value,
            };
            prod.push(i);
            items.push(it);
          });
          window.dataLayer.push({
            event: "eec.checkout",
            ecommerce: {
              checkout: {
                actionField: {
                  step: 2,
                  option: method,
                  action: "checkout",
                },
                products: prod,
              },
            },
          });
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "add_payment_info",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: this.cartTotalWithOutTax,
                  payment_type: method,
                  items: items,
                },
              },
            });
          }
        }
      },
    },
  },
  methods: {
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    setPaymentMethodOptions(paymentMethod, option_key, issuer_id) {
      if (paymentMethod) {
        this.updateLocalStorage({
          billing: paymentMethod,
          issuer: issuer_id,
        });
        this.$store.commit("cart/updateCartPaymentMethodOptions", {
          key: paymentMethod,
          sub: option_key,
          value: issuer_id,
        });
      }
    },
    storage() {
      this.localStorageData =
        JSON.parse(localStorage.getItem("checkoutPayment")) ?? {};
      this.localStorageData.shipping
        ? (this.selectedShippingMethod = this.localStorageData.shipping)
        : "";
      this.localStorageData.delivery
        ? ((this.pickupLocation = this.localStorageData.delivery),
          (this.showPickupLocation = true),
          (this.selectedPickupLocation = this.localStorageData.delivery))
        : "";
      this.localStorageData.billing
        ? (this.selectedPaymentMethod = this.localStorageData.billing)
        : null;
    },
    checkAllSubOptions() {
      this.showPaymentSuboptions = "collapse show";
      this.isPaymentSelected = true;
      this.showPaymentRequired = false;
    },
    updateLocalStorage(data) {
      this.localStorageData = Object.assign({}, this.localStorageData, data);
      localStorage.setItem(
        "checkoutPayment",
        JSON.stringify(this.localStorageData)
      );
    },
    checkFields() {
      var errorFields = [];

      if (!this.shippingMethod && !this.getSelectedShippingMethod) {
        errorFields.push(this.$t("Shipping method"));
      } else if (!this.shippingMethod) {
        this.shippingMethod = this.getSelectedShippingMethod;
      }
      if (!this.selectedPaymentMethod && !this.getSelectedPaymentMethod) {
        errorFields.push(this.$t("Payment method"));
      } else if (this.selectedPaymentMethod == "multisafepay_ideal") {
        if (
          !this.paymentMethodOptions.multisafepay_ideal ||
          !this.paymentMethodOptions.multisafepay_ideal.issuer_id
        ) {
          errorFields.push(this.$t("Bank"));
        }
      }
      if (errorFields.length > 0) {
        this.$store.commit("cart/setStoreValidation", {
          payment: errorFields,
        });
      } else {
        this.$store.commit("cart/setStoreValidation", {
          payment: [],
        });
      }
    },
  },
  mounted() {
    this.storage();
    this.$root.$on("checkFields", () => {
      this.checkFields();
    });
  },
  data: () => ({
    app_url: config.app_url,
    showPaymentRequired: false,
    isPaymentSelected: false,
    selectedIssuer: null,
    localStorageData: { billing: "", delivery: null, issuer: "", shipping: "" },
    showPaymentSuboptions: "collapse show",
  }),
  watch: {
    showPickupLocation() {
      this.setShippingMethod();
    },
    async pickupLocation(code) {
      const response = await this.$store.dispatch("cart/setPickupLocation", {
        code,
      });
      if (response) {
        const delOption = this.selectedShippingMethod.split("|");
        await this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
        this.selectedPickupLocation = await this.$store.getters[
          "cart/getShippingAddress"
        ];
        this.updateLocalStorage({
          shipping: this.selectedShippingMethod,
          delivery: code,
        });
      }
    },
    selectedShippingMethod(method) {
      const [carrier_code, method_code] = method.split("|");
      this.updateLocalStorage({
        shipping: this.selectedShippingMethod,
        delivery: null,
      });
      this.$store.dispatch("cart/addShippingMethod", {
        carrier: carrier_code,
        method: method_code,
      });
      if (this.$gtm.enabled()) {
        let prod = [];
        this.cartItems.forEach((element) => {
          let i = {
            id: element.product.sku,
            name: element.product.name,
            quantity: element.quantity,
          };
          prod.push(i);
        });
        window.dataLayer.push({
          event: "eec.checkout",
          ecommerce: {
            checkout: {
              actionField: {
                step: 3,
                option: method,
                action: "checkout",
              },
              products: prod,
            },
          },
        });
      }
    },
    paymentMethodOptions(method) {
      if (
        method &&
        method.multisafepay_ideal &&
        !method.multisafepay_ideal.issuer_id
      ) {
        if (this.localStorageData.issuer) {
          this.setPaymentMethodOptions(
            "multisafepay_ideal",
            "issuer_id",
            this.localStorageData.issuer
          );
        }
      }
    },
  },
};
</script>

<style>
</style>
