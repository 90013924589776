<template>
  <div class="third-col">
    <span class="group-title"><i class="fas fa-eye"></i>{{ $t("order_overview") }}</span>
    <div class="cart-details">
      <ClientOnly>
        <b-link href="#" v-b-toggle.cart-details-collapse class="collapse-opener">{{ $t("items_in_cart", {
          numberOf:
            cartCount
        }) }}
          <i class="fas fa-chevron-right"></i></b-link>
      </ClientOnly>
      <b-collapse id="cart-details-collapse" visible>
        <div class="single-product" v-for="cartItem of cartItems" :key="cartItem.id">
          <b-link href="#" class="remove" @click="removeCartItem(cartItem.id)">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-link>
          <div class="img">
            <img :src="cartItem.product.image.url" :alt="cartItem.product.name" />
          </div>
          <div class="details">
            <span class="name">{{ cartItem.product.name }}</span>

            <div class="price-part">
              <div class="quantity-input d-flex">
                <b-link class="step-down-btn" @click="quantityDown(cartItem.id, cartItem.quantity)"></b-link>
                <input type="number" min="1" :value="cartItem.quantity" name="quantity" ref="inputQuantity" disabled />
                <b-link class="step-up-btn" @click="quantityUp(cartItem.id, cartItem.quantity)"></b-link>
              </div>
              <div class="price-text">
                {{
                  formatCurrency(cartItem.prices.row_total_including_tax.value)
                }}
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="order-overview">
      <span class="group-subtitle">{{ $t("order_overview") }}</span>
      <ul class="cost-list">
        <li class="">
          <span class="d-block mr-20">{{ $t("subtotal") }}</span>
          <span class="d-block">{{ formatCurrency(cartSubTotalPrice) }}</span>
        </li>
        <li v-for="(price, index) in cartTotalDiscounts" :key="`price-${index}`" class="">
          <span class="d-block mr-20">{{ price.label }}</span>
          <span class="d-block">- {{ formatCurrency(price.amount.value) }}</span>
        </li>
        <li v-if="shippingMethod != null" class="">
          <span class="d-block mr-20">{{ shippingMethod.carrier_title }} /
            {{ shippingMethod.method_title }}</span>
          <span class="d-block">{{
            formatCurrency(shippingMethod.amount_including_tax.value)
          }}</span>
        </li>
        <li v-for="(price, index) in cartTotalTaxPrices" :key="`tax-${index}`" class="">
          <span class="d-block mr-20">{{ price.label }}</span>
          <span class="d-block">{{ formatCurrency(price.amount.value) }}</span>
        </li>
      </ul>
      <div class="total-grp">
        <span class="total">{{ $t("total") }}</span>
        <span class="text-bold">{{ formatCurrency(cartTotalPrice) }}</span>
      </div>
    </div>
    <div class="coupon pt-15 mb-15">
      <ClientOnly>
        <b-link class="collapse-opener group-subtitle border-0" v-b-toggle.coupon-collapse>{{ $t("coupon_discount") }} <i
            class="fas fa-chevron-right"></i></b-link>
      </ClientOnly>
      <b-collapse id="coupon-collapse" collapsed class="pb-15">
        <div class="coupon-wrap">
          <b-form-input v-model="couponCode" type="text" :placeholder="$t('fill_out_discount_code')"
            class="coupon-input"></b-form-input>
          <b-button @click="addCoupon" class="c-btn c-btn-primary">{{
            $t("add")
          }}</b-button>
        </div>
      </b-collapse>
    </div>
    <div class="coupon">
      <ClientOnly>
        <b-link class="collapse-opener group-subtitle border-0" v-b-toggle.remark-collapse>
          {{ this.$t("checkout_note_option") }}
          <i class="fas fa-chevron-right"></i>
        </b-link>
      </ClientOnly>
      <b-collapse id="remark-collapse" collapsed class="pb-15">
        <div class="remark-wrap">
          <b-form-textarea v-model="customerNote" rows="3" max-rows="6" class="remark-input"></b-form-textarea>
          <b-button @click="addRemark" class="c-btn c-btn-primary">{{
            $t("add")
          }}</b-button>
        </div>
      </b-collapse>
    </div>
    <div class="permission">
      <b-form-checkbox class="pl-30" id="newsletter" v-model="SubscribeNewsletter" name="news-letter-subscribe"
        value="yes" unchecked-value="no">{{ $t("subscription_newsletter") }}</b-form-checkbox>
      <!-- <span class="terms">{{ $t("terms_warning") }}</span> -->
      <span class="terms">
        <b-form-checkbox v-model="agreeToTerms" class="pl-30" id="termsCheckbox">
          {{ $t("Ik ga akkoord met de") }}
          <b-link target="_blank"  to="/algemene-voorwaarden">{{ this.$t("ALGEMENE VOORWAARDEN") }}</b-link>
          {{ this.$t("en") }}
          <b-link target="_blank"  to="/klantenservice"> {{ this.$t("PRIVACY VERKLARING") }} </b-link>
        </b-form-checkbox>
      </span>
      <div v-if="showTermsWarning" class="terms-warning-popup">
        *U moet akkoord gaan met de Algemene voorwaarden en Privacy verklaring
      </div>
      <b-button @click="submitForm" v-bind:disabled="buttonClicked" variant="success" class="checkout-btn">{{ $t("pay")
      }}</b-button>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import config from "@config";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "CheckoutOverview",
  components: {
    ClientOnly,
  },
  mixins: [Cart],
  data: () => ({
    couponCode: "",
    customerNote: "",
    SubscribeNewsletter: "no",
    buttonClicked: false,
    localStorageData: {},
    agreeToTerms: false,
    showTermsWarning: false,
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    isCreateAccount() {
      return this.$store.getters["checkout/getCreateAccount"];
    },
    shippingMethod() {
      return this.$store.getters["cart/getShippingMethod"];
    },
    btnDisable() {
      if (this.$store.getters["cart/getShippingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getBillingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getShippingMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getPaymentMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getEmail"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getItemsTotalQuantity"] == 0) {
        return true;
      }
      return false;
    },
    cartTotalWithOutTax: function () {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_excluding_tax != "undefined") {
            if (typeof prices.subtotal_excluding_tax.value != "undefined") {
              return prices.subtotal_excluding_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    cartSubTotal() {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_including_tax != "undefined") {
            if (typeof prices.subtotal_including_tax.value != "undefined") {
              return prices.subtotal_including_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    totalCartTax: function () {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        let totalTax = { value: 0 };
        if (
          typeof prices != "undefined" &&
          typeof prices.applied_taxes == "object"
        ) {
          prices.applied_taxes.forEach(function (amount) {
            totalTax.value += amount.amount.value;
          });
        }
        return [{ amount: totalTax, label: this.$t("VAT") }];
      }
      //return [{ amount: {'value':0}, label: this.$t("VAT") }];
      return this.cartTotalTaxPrices;
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("checkout"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    if (this.$gtm.enabled()) {
      let prod = [];
      let items = [];
      this.cartItems.forEach((element) => {
        let i = {
          id: element.product.sku,
          name: element.product.name,
          quantity: element.quantity,
        };
        prod.push(i);
      });
      this.cartItems.forEach((element) => {
        let i = {
          item_id: element.product.sku,
          item_name: element.product.name,
          quantity: element.quantity,
          currency: "EUR",
          price: element.prices.price.value,
        };
        items.push(i);
      });
      if (!this.$gtm.uaDisabled) {
        //GA event
        window.dataLayer.push({
          event: "eec.checkout",
          ecommerce: {
            checkout: {
              actionField: {
                step: 1,
                option: "",
                action: "checkout",
              },
              products: prod,
            },
          },
        });
      }
      if (this.$gtm.ga4Enabled) {
        //GA4 event
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            add: {
              currency: "EUR",
              value: this.cartTotalWithOutTax,
              items: items,
            },
          },
        });
      }
    }
    this.storage();
    this.$root.$on("checkFields", (errors) => {
      this.checkFields(errors);
    });
  },
  methods: {
    showTermsWarningPopup() {
      if (!this.agreeToTerms) {
        this.showTermsWarning = true;
      } else {
        this.showTermsWarning = false;
      }
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    removeCartItem(id) {
      const cartItems = this.cartItems.filter((v) => v.id != id);
      if (cartItems.length === 0) {
        this.removeItem(id);
        this.$router.push("/checkout/cart");
      } else {
        this.removeItem(id);
      }
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
    async addRemark() {
      if (this.customerNote != "") {
        this.$store.commit("cart/setComment", this.customerNote);
      }
    },
    async submitForm() {
      this.buttonClicked = true;
      this.checkSiblingFields();
      if (this.checkFields()) {
        this.buttonClicked = true;

        if (this.agreeToTerms) {
          if (this.selectedPaymentMethod == "multisafepay_ideal") {
            this.$store.commit("cart/updateCartPaymentMethodOptions", {
              key: this.selectedPaymentMethod,
              sub: "issuer_id",
              value: this.selectedIssuer,
            });
          }

          const retval = await this.$store.dispatch("cart/addPaymentMethod");

          if (retval !== false) {
            localStorage.removeItem("orderHash");

            if (retval.order.hash) {
              localStorage.setItem("orderHash", retval.order.hash);
            }

            const url = retval.redirect_url.replace(config.app_url, "");

            if (this.isLoggedIn == true) {
              this.$store.dispatch("user/loadOrders", { page: 1, perPage: 10 });
            } else {
              if (this.isCreateAccount == true) {
                this.$store.dispatch("checkout/changeCreateAccount", false);
                this.$store.commit("cart/setShippingAddress", null);
                this.createAccount();
              }
            }

            if (url.includes("https://")) {
              window.location = url;
            } else {
              this.$store.dispatch("cart/loadCart");
              this.$router.push({
                path: url + "?transactionid=" + retval.order.increment_id,
                params: { hash: retval.order.hash },
              });
            }
          }
        } else {
          this.showTermsWarningPopup();
          this.buttonClicked = false; // Schakel de knop opnieuw in omdat het formulier niet wordt ingediend
        }
      }
    },
    storage() {
      this.localStorageData =
        JSON.parse(localStorage.getItem("checkoutOverview")) ?? {};
      this.localStorageData.delivery
        ? ((this.pickupLocation = this.localStorageData.delivery),
          (this.showPickupLocation = true),
          (this.selectedPickupLocation = this.localStorageData.delivery))
        : "";
      this.localStorageData.billing
        ? (this.selectedPaymentMethod = this.localStorageData.billing)
        : null;
      this.localStorageData.issuer
        ? ((this.selectedIssuer = this.localStorageData.issuer),
          (this.isPaymentSelected = true),
          (this.showPaymentSuboptions = "collapse show"),
          this.setPaymentMethodOptions(
            this.localStorageData.billing,
            "issuer_id",
            this.localStorageData.issuer
          ))
        : null;
      this.localStorageData.newsletter
        ? (this.SubscribeNewsletter = this.localStorageData.newsletter)
        : (this.SubscribeNewsletter = "yes");
    },
    updateLocalStorage(data) {
      this.localStorageData = Object.assign({}, this.localStorageData, data);
      localStorage.setItem(
        "checkoutOverview",
        JSON.stringify(this.localStorageData)
      );
    },
    checkFields() {
      var errorFields = [];
      var storeValidation = this.$store.getters["cart/getStoreValidation"];
      for (const property in storeValidation) {
        storeValidation[property].forEach((error) => {
          errorFields.push(error);
        });
      }
      if (errorFields.length > 0) {
        const msg = {
          type: "danger",
          title: this.$t("Checkout fields incomplete"),
          text: this.$t("The following fields are invalid:", {
            errors: errorFields.join(", "),
          }),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        return false;
      }
      return true;
    },
    checkSiblingFields() {
      this.$root.$emit("checkFields");
    },
  },
  watch: {
    SubscribeNewsletter() {
      this.updateLocalStorage({ newsletter: this.SubscribeNewsletter });
    },

    agreeToTerms(value) {
      if (value) {
        this.showTermsWarning = false;
      }
    }
  },
};
</script>

<style></style>
