<template>
  <div>
    <NotLoggedInCheckoutDetails v-if="!isLoggedIn" />
    <LoggedInCheckoutDetails v-else />
  </div>
</template>

<script>
import NotLoggedInCheckoutDetails from "@/base/core/components/checkout/NotLoggedInCheckoutDetails.vue";
import LoggedInCheckoutDetails from "@/base/core/components/checkout/LoggedInCheckoutDetails.vue";

export default {
  name: "CheckoutAddress",
  components: {
    NotLoggedInCheckoutDetails,
    LoggedInCheckoutDetails,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
};
</script>

<style>
</style>